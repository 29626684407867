/* -----------------------------------------------------------
   All Styles
  ----------------------------------------------------------- */

@import "bootstrap";
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,700i&display=swap");

/*
 * Colors

  #2222FF - bluebonnet 
  #202030 - dark gunmetal  
  #FFA9E7 - lavender rose 

 */

/*
 * Global Styles
 */

body {
  font-family: "Playfair Display", serif;
}

html {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 992px) {
    font-size: 18px;
  }
}

* {
  border-radius: 0 !important;
}

ul {
  list-style: none !important;
}

footer .form-control {
  border: 0 !important;
}

/*
 * Header
 */

.masthead {
  height: 100vh;
  min-height: 500px;
  background-image: url(../images/header.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.rooms-masthead {
  background-image: url(../images/rooms-header.jpg);
}

.blog-masthead {
  background-image: url(../images/blog-header.jpg);
}

.blog-article-masthead {
  background-image: url(../images/beach.jpg);
}

.contact-masthead {
  background-image: url(../images/tropical-beach.jpg);
}

/*
 * Cards
 */

.card-title,
.blog-title {
  font-size: 1.5rem;
}

.card-subtitle {
  font-size: 1.2rem;
}

/*
 * Icons
 */

.icons .fas {
  font-size: 20px;
  color: #2222ff;
  padding: 5px 10px 0px 10px;
}

.icons .fab {
  font-size: 30px;
  color: #fff;
}

/*
 * Footer
 */

footer {
  background: #202030;
  color: #fff;
}

footer h3 {
  font-size: 1rem;
}

footer a {
  color: #fff;
}

footer a:hover {
  color: #ccc;
}

/*
 * Navigation
 */

.navbar {
  text-transform: uppercase;
  background: #21509b !important;
}

.navbar-brand {
  font-size: 1.5rem;
  letter-spacing: 0.5rem;
}

/*
 * Image Slider
 */

/* carousel fullscreen */

.carousel-fullscreen .carousel-inner .carousel-item {
  height: 100vh;
  min-height: 600px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* carousel fullscreen - vertically centered caption */

.carousel-fullscreen .carousel-caption {
  top: 50%;
  bottom: auto;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

/* Set up Slide background images */

.slide-1 {
  background: url("../images/beach.jpg") #21acad;
}

.slide-2 {
  background: url("../images/dinning-room.jpg") #8140a0;
}

.slide-3 {
  background: url("../images/hotel-room.jpg") #c73816;
}

.rooms-slide-1 {
  background: url("../images/rooms-slide-1.jpg") #8140a0;
}

.rooms-slide-2 {
  background: url("../images/rooms-slide-2.jpg") #c73816;
}

.rooms-slide-3 {
  background: url("../images/rooms-slide-3.jpg") #21acad;
}

.rooms-slide-4 {
  background: url("../images/rooms-slide-4.jpg") #8140a0;
}

/* overlay for better readibility of the caption  */

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
  transition: all 0.2s ease-out;
}
